<template>
  <div class="main-content">
    <h2 class="mb-2">
      <i class="fas fa-cog"
        aria-hidden="true" />
      {{ $t('company-security-settings.security-settings') }}
    </h2>

    <section class="d-flex flex-column">
      <el-tabs v-model="activeTab">
        <!-- SSO tab -->
        <el-tab-pane :label="$t('company-security-settings.sso-long')"
          :name="SSO_TAB">
          <sso ref="ssoTab" />
        </el-tab-pane>

        <!-- MFA tab -->
        <el-tab-pane :label="$t('company-security-settings.mfa-long')"
          :name="MFA_TAB">
          <mfa ref="mfaTab" />
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>

<script>
import CompanySecuritySettingsMfa from './CompanySecuritySettingsMfa.vue'
import CompanySecuritySettingsSso from './CompanySecuritySettingsSso.vue'
import { mapGetters } from 'vuex'

// Tab names
const MFA_TAB = 'mfa'
const SSO_TAB = 'sso'

export default {

  components: {
    mfa: CompanySecuritySettingsMfa,
    sso: CompanySecuritySettingsSso,
  },

  async beforeRouteLeave (_to, _from, next) {
    const ssoTab = this.$refs.ssoTab
    const ssoHasChanges = (ssoTab.ssoSettingChanged || ssoTab.$refs.idpForm?.isDirty)

    if (!ssoHasChanges && !this.$refs.mfaTab.mfaSettingChanged || !this.hasCompany) {
      next()
      return
    }

    try {
      await this.$confirm(
        this.$t('general.unsaved-changes-confirm-message'),
        this.$t('general.unsaved-changes-confirm-title'),
        {
          cancelButtonText: this.$t('general.unsaved-changes-confirm-cancel'),
          confirmButtonText: this.$t('general.unsaved-changes-confirm-ok'),
        }
      )

      next()
    } catch {
      return false
    }
  },

  data() {
    return {
      activeTab: SSO_TAB,
      SSO_TAB,
      MFA_TAB,
    }
  },

  computed: {
    ...mapGetters('Company', ['hasCompany']),
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 800px;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

::v-deep .el-tabs__content {
  margin-top: 1.5rem;
}
</style>
