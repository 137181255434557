<template>
  <section>
    <el-form ref="idpForm"
      :model="idpForm"
      :rules="idpFormRules">
      <h4 v-if="!isNew"
        class="mb-2">
        {{ $t('company-security-settings.details-for', { thing: idpDetails.name }) }}
      </h4>

      <i18n v-if="isNew"
        path="company-security-settings.sso-new-docs-message"
        tag="p"
        class="mt-0">
        <template #docs>
          <a href="https://docs.megaport.com/troubleshooting/sso-terms-conditions/"
            data-testid="sso-terms-conditions"
            target="_blank">{{ $t('company-security-settings.sso-features-and-instructions-for-use') }}</a>
        </template>
      </i18n>

      <h4 v-if="isNew"
        class="mb-2">
        {{ $t('company-security-settings.register-saml-provider') }}
      </h4>
      <p v-if="isNew"
        class="mt-0">
        {{ $t('company-security-settings.register-saml-provider-description') }}
      </p>

      <!-- Identity provider information -->
      <fieldset class="auth-fieldset mt-2">
        <legend class="mb-1 text-align-center">
          {{ $t('company-security-settings.identity-provider-info') }}
        </legend>

        <el-form-item prop="name"
          :label="$t('company-security-settings.provider-name')"
          :label-width="labelWidth">
          <el-input v-model="idpForm.name"
            :disabled="!isNew"
            name="name"
            data-demo="demo-provider"
            data-testid="provider-name" />
        </el-form-item>

        <el-form-item prop="domains"
          :label-width="labelWidth">
          <template #label>
            {{ $t('company-security-settings.domains') }}
            <el-tooltip placement="top"
              :content="$t('company-security-settings.accepts-csv-values')"
              :open-delay="500">
              <i class="fas fa-question-circle"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <el-input v-model="idpForm.domains"
            aria-describedby="domain-description"
            name="domains"
            data-demo="megaport.com"
            data-testid="domains" />
        </el-form-item>

        <el-form-item prop="metadataUrl"
          :label="$t('company-security-settings.metadata')"
          :label-width="labelWidth">
          <el-input v-model="idpForm.metadataUrl"
            :disabled="!isNew"
            name="metadataUrl"
            data-demo="https://demo-metadata-url-d0b5c4d8.com/"
            data-testid="metadata-url" />
        </el-form-item>
      </fieldset>

      <!-- Attribute mapping only supports email and currently there are no plans to support more fields, so this is somewhat hardcoded for now -->
      <h4 class="mb-1 text-align-center">
        {{ $t('company-security-settings.sso-attribute-mapping') }}
      </h4>
      <table class="full-width">
        <thead>
          <th>{{ $t('company-security-settings.sso-user-pool-attribute') }}</th>
          <th />
          <th class="required">
            {{ $t('company-security-settings.sso-saml-attribute') }}
          </th>
        </thead>
        <tbody>
          <tr v-for="attribute in ssoAttributeMapping"
            :key="attribute">
            <td>
              <el-form-item>
                <el-input :value="attribute"
                  disabled
                  :name="`mapping-${attribute}`"
                  :data-testid="`mapping-${attribute}`" />
              </el-form-item>
            </td>
            <td class="text-align-center">
              <i class="el-icon-right"
                aria-hidden="true" />
            </td>
            <td>
              <el-form-item prop="emailMapping">
                <el-input v-model="idpForm.emailMapping"
                  name="emailMapping"
                  data-demo="email.mapping"
                  data-testid="email-mapping" />
              </el-form-item>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end">
        <el-button type="secondary"
          data-testid="sso-back-button"
          @click="cancel">
          {{ $t('general.back') }}
        </el-button>
        <el-button type="primary"
          data-testid="sso-save-button"
          @click="saveDetails">
          {{ $t('general.save') }}
        </el-button>
      </div>
    </el-form>
  </section>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'
import { validateForm } from '@/utils/form.js'
import { copyToClipboard } from '@/helpers.js'
import { isEqual } from 'lodash'

export default {
  props: {
    idpDetails: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['cancel', 'save'],

  data() {
    return {
      // Currently only support mapping of a single attribute, which is hardcoded to be email.
      ssoAttributeMapping: ['email'],
      labelWidth: '140px',
      idpForm: {},
      idpFormRules: {
        name: [
          { required: true, message: this.$t('validations.required', { thing: 'Provider Name' }), trigger: 'blur' },
          { validator: this.validateIdpName, trigger: 'blur' },
        ],
        emailMapping: [
          { required: true, message: this.$t('validations.required', { thing: 'Email mapping attribute' }), trigger: 'blur' },
          { max: 100, message: this.$tc('validations.max-length', 100, { max: 100 }), trigger: 'blur' },
        ],
        metadataUrl: [
          { required: true, message: this.$t('validations.required', { thing: 'Metadata URL' }), trigger: 'blur' },
          { type: 'url', message: this.$t('validations.url-format', { site: 'Metadata' }), trigger: 'blur' },
        ],
        domains: [
          { required: true, message: this.$t('validations.required', { thing: 'Domains' }), trigger: 'blur' },
          { validator: this.validateDomains, trigger: 'blur' },
        ],
      },
    }
  },


  computed: {
    ...mapState('Company', { company: state => state.data }),
    ...mapGetters('Company', ['hasCompany']),
    isDirty() {
      return !isEqual(this.idpForm, this.idpDetails)
    },
  },

  created() {
    if (!this.isNew) this.idpForm = { ...this.idpDetails }
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad']),
    copyToClipboard,

    validateIdpName(_rule, value, callback) {
      const IDP_NAME_REGEX = /^[a-z0-9-]+$/
      const IDP_NAME_MIN_LEN = 3
      const IDP_NAME_MAX_LEN = 23

      const idpNameLength = value.length
      if (idpNameLength < IDP_NAME_MIN_LEN || idpNameLength > IDP_NAME_MAX_LEN) {
        return callback(this.$t('validations.min-max-length', { min: IDP_NAME_MIN_LEN, max: IDP_NAME_MAX_LEN }))
      } else if (!IDP_NAME_REGEX.test(value)) {
        callback(this.$t('validations.idp-name-invalid-characters'))
        return
      } else {
        callback()
      }
    },

    validateDomains(_rule, value, callback) {
      const MAXIMUM_NUMBER_OF_DOMAINS = 100
      const domains = value.split(',')

      if (domains.length > MAXIMUM_NUMBER_OF_DOMAINS) {
        callback(new Error(this.$t('company-security-settings.maximum-domains-error', { max: MAXIMUM_NUMBER_OF_DOMAINS })))
        return
      }

      const DOMAIN_REGEX = /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.)+([a-zA-Z]{2,63})$/

      const MAXIMUM_LENGTH_OF_DOMAIN_STRING = 70
      const MAXIMUM_DOMAIN_LENGTH = 253

      const errors = domains.reduce((accum, item) => {
        const domain = item.trim()

        if (!DOMAIN_REGEX.test(domain) || domain.length > MAXIMUM_DOMAIN_LENGTH) {
          accum.invalid.add(domain)
        }

        if (accum[domain]) {
          accum.duplicates.add(domain)
        }

        accum[domain] = true

        return accum
      }, { duplicates: new Set(), invalid: new Set() })

      const setToErrorMessage = s => Array.from(s).join(',').substring(0, MAXIMUM_LENGTH_OF_DOMAIN_STRING)

      if (errors.invalid.size > 0) {
        callback(this.$t('company-security-settings.invalid-domains', { domains: setToErrorMessage(errors.invalid) }))
      } else if (errors.duplicates.size > 0) {
        callback(this.$t('company-security-settings.duplicate-domains', { domains: setToErrorMessage(errors.duplicates) }))
      } else {
        callback()
      }
    },

    handleError(error) {
      /* | has a weird special meaning in BE, error message will be after | */
      if (error.message && error.message.includes('|')) {
        const [, message] = error.message.split('|')
        this.notifyBad({ title: message })
        return
      }

      this.notifyBad({ title: this.$t('company-security-settings.error-updating-sso') })
    },

    async createIdp() {
      try {
        const newIdp = await sdk.instance.sso().create(this.idpForm)
        this.$emit('save', newIdp)
      } catch (error) {
        this.handleError(error)
      } finally {
        this.$emit('loading', false)
      }
    },

    async updateIdp() {
      try {
        /* Update only accepts idpId, domains, emailMapping currently */
        const payload = {
          idpId: this.idpForm.id,
          domains: this.idpForm.domains,
          emailMapping: this.idpForm.emailMapping,
        }
        await sdk.instance.sso().update(payload)
        this.$emit('save', this.idpForm)
      } catch (error) {
        this.handleError(error)
      } finally {
        this.$emit('loading', false)
      }
    },

    async saveDetails() {
      try {
        await validateForm(this.$refs.idpForm)
      } catch {
        return /* error displays inline if this fails */
      }

      this.$emit('loading', true)
      this.isNew ? await this.createIdp() : await this.updateIdp()
    },

    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-input.is-disabled .el-input__inner {
  color: var(--color-text-regular);
}

th.required:before {
  content: "*";
  color: #e01c1c;
  margin-right: 4px;
}
</style>
