<template>
  <section>
    <h3 class="mb-1">
      {{ $t('company-security-settings.mfa-long') }}
    </h3>
    <fieldset class="mfa-enforce-fieldset">
      <legend>
        {{ $t('company-security-settings.enforce-mfa-all-users') }}
        <el-popover placement="top"
          :title="$t('company-security-settings.enforce-mfa-tooltip-title')"
          width="400"
          trigger="hover">
          <p class="word-break-normal">
            {{ $t('company-security-settings.enforce-mfa-tooltip-on') }}
          </p>
          <p class="word-break-normal">
            {{ $t('company-security-settings.enforce-mfa-tooltip-off') }}
          </p>
          <span slot="reference">
            <i class="fa fa-lg fa-info-circle"
              data-testid="mfa-settings-enforce-tooltip"
              aria-hidden="true" />
          </span>
        </el-popover>
      </legend>

      <el-switch v-model="mfaSettings.enforceMfa"
        :active-text="$t('company-security-settings.on')"
        :inactive-text="$t('company-security-settings.off')"
        data-testid="mfa-settings-enforce-switch" />

      <el-alert v-if="mfaSettingChanged"
        type="info"
        :title="infoText"
        :closable="false"
        show-icon />

      <div class="text-align-right">
        <el-button type="primary"
          class="right"
          :disabled="!mfaSettingChanged"
          data-testid="mfa-settings-save-button"
          @click="saveSettings()">
          {{ $t('general.save') }}
        </el-button>
      </div>
    </fieldset>
  </section>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      mfaSettings: {
        enforceMfa: false,
      },
    }
  },

  computed: {
    ...mapState('Company', { company: state => state.data }),
    mfaSettingChanged() {
      return this.company.enforceMfa !== this.mfaSettings.enforceMfa
    },
    infoText() {
      return this.mfaSettings.enforceMfa && this.mfaSettingChanged
        ? this.$t('company-security-settings.info-enforced')
        : this.$t('company-security-settings.info-optional')
    },
  },

  watch: {
    company: {
      immediate: true,
      handler() {
        this.resetForm()
      },
    },
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    ...mapActions('Company', ['updateCompany']),

    async saveSettings() {
      try {
        await this.updateCompany({ enforceMfa: this.mfaSettings.enforceMfa })
        const enforcedOrOptional = this.company.enforceMfa ? 'enforced' : 'optional'
        this.notifyGood({ title: this.$t(`company-security-settings.mfa-now-${enforcedOrOptional}`) })
      } catch (e) {
        this.notifyBad({ title: window.mpApp.$t('company-security-settings.error-updating-mfa') })
      }
    },

    resetForm() {
      this.mfaSettings.enforceMfa = this.company.enforceMfa
    },
  },
}
</script>

<style lang="scss" scoped>
.mfa-enforce-fieldset {
  display: flex;
  flex-direction: column;
  gap: 1em;
  border: none;
  padding: 0;
  margin: 0;

   legend {
    padding-inline: 0;
    padding: 0.5em 0em;
    font-size: 1.4rem;
    color: #606266;
    line-height: 1.2em;
  }
}
</style>
