<template>
  <!-- SSO enforcement toggle -->
  <section v-loading="showLoadingSpinner">
    <h3 class="mb-2">
      {{ $t('company-security-settings.sso-long') }}
    </h3>

    <!-- Integration details -->
    <el-alert v-if="!showDetailsView"
      class="p-0 mb-2"
      :closable="false">
      <el-collapse value="show-integration-details">
        <el-collapse-item :title="$t('company-security-settings.integration-details')"
          name="show-integration-details">
          <p class="m-0">
            {{ $t('company-security-settings.integration-details-desc') }}
          </p>
          <el-form>
            <el-form-item prop="audienceUri"
              :label-width="labelWidth">
              <template #label>
                {{ $t('company-security-settings.audience-uri') }}
                <el-popover trigger="hover"
                  placement="top">
                  {{ $t('company-security-settings.can-also-be-known-as') }}
                  <ul>
                    <li>{{ $t('company-security-settings.entity-id') }}</li>
                    <li>{{ $t('company-security-settings.audience') }}</li>
                  </ul>
                  <span slot="reference">
                    <i class="el-icon-info color-info"
                      aria-hidden="true" />
                  </span>
                </el-popover>
              </template>
              <el-input :value="ssoData.audienceUri"
                readonly
                data-testid="audience-uri"
                @focus="$event.target.select()">
                <el-tooltip slot="append"
                  placement="top"
                  :content="$t('general.click-copy')"
                  :open-delay="500">
                  <el-button @click="copyToClipboard(ssoData.audienceUri)">
                    <i class="far fa-clipboard"
                      aria-hidden="true" />
                  </el-button>
                </el-tooltip>
              </el-input>
            </el-form-item>

            <el-form-item prop="idpResponseUrl"
              :label-width="labelWidth">
              <template #label>
                {{ $t('company-security-settings.idp-response-url') }}
                <el-popover trigger="hover"
                  placement="top">
                  {{ $t('company-security-settings.can-also-be-known-as') }}
                  <ul>
                    <li>{{ $t('company-security-settings.assertion-consumer-service') }}</li>
                    <li>{{ $t('company-security-settings.single-sign-on-url') }}</li>
                    <li>{{ $t('company-security-settings.application-callback-url') }}</li>
                    <li>{{ $t('company-security-settings.reply-url') }}</li>
                  </ul>
                  <span slot="reference">
                    <i class="el-icon-info color-info"
                      aria-hidden="true" />
                  </span>
                </el-popover>
              </template>
              <el-input :value="ssoData.idpResponseUrl"
                readonly
                data-testid="idp-response-url"
                @focus="$event.target.select()">
                <el-tooltip slot="append"
                  placement="top"
                  :content="$t('general.click-copy')"
                  :open-delay="500">
                  <el-button @click="copyToClipboard(ssoData.idpResponseUrl)">
                    <i class="far fa-clipboard"
                      aria-hidden="true" />
                  </el-button>
                </el-tooltip>
              </el-input>
            </el-form-item>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </el-alert>

    <!-- IDP details view -->
    <idp-form v-if="showDetailsView"
      ref="idpForm"
      :idp-details="activeIdpDetails"
      :is-new="isNew"
      @cancel="idpFormCancel"
      @save="idpFormSave"
      @loading="showLoadingSpinner = $event" />

    <!-- if !showDetailsView -->
    <template v-else-if="hasIdentityProvider">
      <section>
        <h4>{{ $t('company-security-settings.enforce-sso') }}</h4>
        <fieldset class="sso-enforce-fieldset">
          <legend>
            {{ $t('company-security-settings.enforce-sso-all-users') }}
            <el-popover placement="top"
              :title="$t('company-security-settings.enforce-sso-tooltip-title')"
              width="400"
              trigger="hover">
              <p class="word-break-normal">
                {{ $t('company-security-settings.enforce-sso-tooltip-on') }}
              </p>
              <p class="word-break-normal">
                {{ $t('company-security-settings.enforce-sso-tooltip-off') }}
              </p>
              <span slot="reference">
                <i class="fa fa-lg fa-info-circle"
                  data-testid="sso-settings-enforce-tooltip"
                  aria-hidden="true" />
              </span>
            </el-popover>
          </legend>

          <el-switch v-model="ssoSettings.enforceSso"
            :active-text="$t('company-security-settings.on')"
            :inactive-text="$t('company-security-settings.off')"
            data-testid="sso-settings-enforce-switch" />

          <el-alert v-if="ssoSettingChanged"
            type="info"
            :title="enforceSsoInfoText"
            :closable="false"
            show-icon />

          <div class="text-align-right">
            <el-button type="primary"
              class="right"
              :disabled="!ssoSettingChanged"
              data-testid="sso-settings-save-button"
              @click="saveSsoEnforcementSetting">
              {{ $t('general.save') }}
            </el-button>
          </div>
        </fieldset>
      </section>

      <!-- SSO table -->
      <section>
        <div class="d-flex mt-1 mb-1 justify-content-space-between">
          <h4>{{ $t('company-security-settings.sso-connections') }}</h4>
          <el-button v-if="canAddConnections"
            type="primary"
            data-testid="sso-add-saml-table-button"
            @click="showCreateIdpForm">
            {{ $t('company-security-settings.add-saml-connection') }}
          </el-button>
        </div>
        <el-table :data="ssoData.identityProviders"
          :empty-text="$t('company-security-settings.sso-no-idps')"
          data-testid="sso-security-table">
          <!-- Provider identity -->
          <el-table-column prop="name"
            :label="$t('company-security-settings.provider-identity')"
            min-width="150" />
          <!-- Type-->
          <el-table-column prop="type"
            :label="$t('company-security-settings.type')" />
          <!-- Actions -->
          <el-table-column :label="$t('general.actions')"
            header-align="center"
            align="center">
            <template #default="{ row, $index }">
              <el-button type="primary"
                plain
                :data-testid="`sso-edit-${$index}`"
                @click="editIdpDetails(row)">
                {{ $t('general.edit') }}
              </el-button>

              <el-button type="danger"
                plain
                :data-testid="`sso-delete-${$index}`"
                @click="deleteIdp(row)">
                {{ $t('general.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </section>
    </template>

    <!-- Initial view with no SSO connections added and not in details mode -->
    <section v-else>
      <h4>{{ $t('company-security-settings.configure-saml-idp') }}</h4>
      <div class="d-flex flex-align-center gap-3">
        <p>{{ $t('company-security-settings.configure-saml-description') }}</p>
        <el-button type="primary"
          data-testid="sso-add-saml-button"
          @click="showCreateIdpForm">
          {{ $t('company-security-settings.add-saml-connection') }}
        </el-button>
      </div>
    </section>
  </section>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import sdk from '@megaport/api-sdk'
import { copyToClipboard } from '@/helpers.js'
import CompanySecuritySettingsSsoIdpFormVue from './CompanySecuritySettingsSsoIdpForm.vue'

export default {
  components: {
    'idp-form': CompanySecuritySettingsSsoIdpFormVue,
  },

  data() {
    return {
      labelWidth: '200px',

      lastSavedIdentityProviderId: null,

      showLoadingSpinner: false,

      ssoData: {
        audienceUri: '',
        idpResponseUrl: '',
        ssoEnforced: false,
        idpLimit: 1,
        identityProviders: [],
      },

      ssoSettings: {
        enforceSso: false,
      },

      activeIdpDetails: {},
      showDetailsView: false,
      isNew: false,
    }
  },

  computed: {
    hasIdentityProvider() {
      return this.ssoData.identityProviders?.length > 0
    },

    canAddConnections() {
      return this.ssoData.identityProviders?.length < this.ssoData.idpLimit
    },

    ssoSettingChanged() {
      return this.ssoData.ssoEnforced !== this.ssoSettings.enforceSso
    },

    enforceSsoInfoText() {
      return this.ssoSettings.enforceSso && this.ssoSettingChanged
        ? this.$t('company-security-settings.enforce-sso-info-enforced')
        : this.$t('company-security-settings.enforce-sso-info-optional')
    },

    lastSavedIdentityProvider() {
      if (!this.lastSavedIdentityProviderId) return
      return this.ssoData.identityProviders.find(idp => idp.id === this.lastSavedIdentityProviderId)
    },
  },

  async created() {
    try {
      await this.getSsoConfig()

      // This handles context change from managed company, make sure we refresh sso config afterwards.
      window.mpApp.$on('changeContext', this.getSsoConfig)
    } catch (error) {
      console.error(error)
      this.notifyBad({
        title: this.$t('company-security-settings.error-fetching-sso'),
      })
    }
  },

  destroyed() {
    window.mpApp.$off('changeContext')
  },

  methods: {
    ...mapActions('Users', ['syncUsers']),
    copyToClipboard,
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),

    async idpFormSave(idp) {
      await this.getSsoConfig()
      this.lastSavedIdentityProviderId = idp.id
      if (this.isNew) {
        this.notifyGood({ title: this.$t('company-security-settings.sso-add-settings-for-account') })
      } else {
        this.notifyGood({ title: this.$t('company-security-settings.sso-update-success', { name: this.lastSavedIdentityProvider.name }) })
      }

      this.isNew = false
      this.showDetailsView = false
    },

    idpFormCancel() {
      this.isNew = false
      this.showDetailsView = false
    },

    showCreateIdpForm() {
      this.showDetailsView = true
      this.isNew = true
    },

    resetSettingsForm() {
      this.ssoSettings.enforceSso = this.ssoData.ssoEnforced
    },

    async saveSsoEnforcementSetting() {
      try {
        this.showLoadingSpinner = true

        await sdk.instance.sso().setEnforced(this.ssoSettings.enforceSso)

        // need to refresh the user list to fetch updated ssoStatus after enforcement change
        await this.syncUsers()

        await this.getSsoConfig()

        const enforcedOrOptional = this.ssoData.ssoEnforced ? 'enforced' : 'optional'
        this.notifyGood({ title: this.$t(`company-security-settings.sso-now-${enforcedOrOptional}`) })
      } catch (e) {
        this.notifyBad({ title: this.$t('company-security-settings.error-updating-sso') })
      } finally {
        this.showLoadingSpinner = false
      }
    },

    /**
     * Get SSO config info from API to display in table
     */
    async getSsoConfig() {
      try {
        this.showLoadingSpinner = true
        const data = await sdk.instance.sso().config()

        // Patch in type and fix domains formatting for use in text input
        const identityProviders = data.identityProviders.map(idp => {
          return {
            ...idp,
            domains: idp.domains.join(','),
            type: 'SAML',
          }
        })

        this.ssoData = {
          ...data,
          identityProviders,
        }

        this.resetSettingsForm()
      } catch (error) {
        const errorStr = error.data?.message ?? error
        this.notifyBad({
          title: this.$t('company-security-settings.sso-fail'),
          message: errorStr,
        })
      } finally {
        this.showLoadingSpinner = false
      }
    },
    /**
     * View SSO details for selected configuration
     * @param {Object} data Data to view
     */
    editIdpDetails(data) {
      this.isNew = false
      this.activeIdpDetails = data
      this.showDetailsView = true
    },

    async deleteIdp(data) {
      try {
        await this.$confirm(
          this.$t('company-security-settings.idp-delete-confirmation-message'),
          this.$t('company-security-settings.idp-delete-confirmation-title'),
          {
             confirmButtonText: this.$t('general.delete'),
             confirmButtonClass: 'el-button--danger',
             dangerouslyUseHTMLString: true,
          }
        )
        this.showLoadingSpinner = true

        await sdk.instance.sso().delete(data.id)
        await this.getSsoConfig()
      } catch (error) {
        // this is from the $confirm modal
        if (error === 'cancel') return

        // sdk request error
        const errorStr = error.data?.message ?? error
        this.notifyBad({
          title: this.$t('company-security-settings.sso-fail'),
          message: errorStr,
        })
      } finally {
        this.showLoadingSpinner = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sso-enforce-fieldset {
  display: flex;
  flex-direction: column;
  gap: 1em;
  border: none;
  padding: 1em 0;
  margin: 0;

   legend {
    padding-inline: 0;
    padding: 0.5em 0em;
    font-size: 1.4rem;
    color: #606266;
    line-height: 1.2em;
  }
}

table .el-form-item {
  margin: 0;
}

.integration-details h4 {
  color: black;
}

.gap-3 {
  gap: 3em;
}

::v-deep {
  .el-alert__description {
    margin: 0;
  }
  .el-alert__content {
    width: 100%;
  }
  .el-dialog__body {
    padding: 0 20px;
  }
  .el-collapse {
    border: none;
    width: 100%;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
  }
  .el-collapse-item__header {
    font-size: 1.8rem;
    background: transparent;
  }
  .el-collapse-item__wrap {
    border: none;
    background: transparent;
  }
}
</style>
